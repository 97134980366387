import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card/Card';
import CardActions from '@material-ui/core/CardActions/CardActions';
import CardContent from '@material-ui/core/CardContent/CardContent';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import Collapse from '@material-ui/core/Collapse/Collapse';
import IconButton from '@material-ui/core/IconButton/IconButton';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography/Typography';
import HelpIcon from '@material-ui/icons/Help';
import I18n from 'i18n-js';
import React, { useState, KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { LOGIN, MODULES } from '../config/routes';
import generateUrl from '../services/generateUrl';
import { LOGIN_STATE_LOGGED_IN } from '../redux/store/initialState';
import { Store } from '../types';

interface Props extends Pick<RouteComponentProps, 'history'> {}

const HomeScreen = (props: Props) => {
	const loggedIn = useSelector(
		({ currentUser: { loginState } }: Store) => loginState === LOGIN_STATE_LOGGED_IN
	);
	const message = useSelector(({ currentUser: { message } }: Store) => message);

	const [token, setToken]: [string, Function] = useState('');
	const [expanded, setExpanded]: [boolean, Function] = useState(false);

	const { history } = props;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setToken(e.target.value);
	};

	const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>): void => {
		if (token.length >= 6 && e.key === 'Enter') {
			handleLogin();
		}
	};

	const handleLogin = () => {
		return history.push(
			generateUrl(LOGIN, {
				':token': token
			})
		);
	};

	if (loggedIn) {
		return <Redirect to={MODULES} />;
	}

	return (
		<div className="screen-centered">
			<Card>
				<CardHeader
					data-test-id="homescreen_login-title"
					title={I18n.t('login.title')}
					subheader={I18n.t('login.message')}
					action={
						<IconButton data-test-id="homescreen_hint-btn" onClick={() => setExpanded(!expanded)}>
							<HelpIcon />
						</IconButton>
					}
				/>

				<CardContent>
					<TextField
						data-test-id="homescreen_text-field"
						fullWidth={true}
						placeholder={I18n.t('login.tokenPlaceholder')}
						onKeyPress={handleKeyPress}
						onChange={handleChange}
						value={token}
						name="tokenField"
					/>
				</CardContent>

				<CardActions>
					<Button
						data-test-id="homescreen_login-btn"
						variant="contained"
						color="primary"
						onClick={handleLogin}
						disabled={!(token.length >= 6)}
						fullWidth
					>
						{I18n.t('login.title')}
					</Button>
				</CardActions>

				<Collapse data-test-id="homescreen_hint-content" in={expanded} timeout="auto" unmountOnExit>
					<CardContent>
						<Typography
							dangerouslySetInnerHTML={{
								__html: I18n.t('login.hint')
							}}
						/>
					</CardContent>
				</Collapse>
			</Card>
			{message ? <p style={{ color: '#e82020' }}>{message}</p> : null}
		</div>
	);
};

export default HomeScreen;
