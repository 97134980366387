export const toBase64 = (file: File) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export function dataURLtoFile(dataurl: string, filename: string) {
	const arr = dataurl.split(',');
	const match = arr[0].match(/:(.*?);/);
	if (!match) throw new Error('Invalid base64 url used');
	const mime = match[1];
	const bstr = atob(arr[arr.length - 1]);
	let n = bstr.length;
	let u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}
